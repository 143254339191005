<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card>
                    <v-card-title class="title">
                        Daily Quotes
                        <v-spacer></v-spacer>
                        <add-button @action="form.dialog = true" permission="album-create">
                            New Quote
                        </add-button>
                    </v-card-title>
                    <v-data-table
                            footer-props.items-per-page-options="rowsPerPageItems"
                            :headers="headers"
                            :hide-default-footer="form.items.meta.total<pagination.rowsPerPage"
                            :items="form.items.data"
                            :options.sync="pagination"
                            :server-items-length="form.items.meta.total"
                            :loading="form.loading">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left">{{ item.description }}</td>
                                <td class="text-xs-center"><strong>{{ item.author || '-' }}</strong></td>
                                <td class="text-xs-right">
                                    <edit-button permission="school-create" @agree="form.edit(item)"/>
                                    <!--<delete-button permission="school-create" @agree="form.delete(props.item.id)"/>-->
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
            <v-dialog v-model="form.dialog" persistent max-width="500px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Album Details</span>
                    </v-card-title>
                    <v-card-text class="pa-1">
                        <v-form ref="form" @submit.prevent="store"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="store"
                                lazy-validation>
                            <v-container grid-list-md>
                                <v-layout row wrap>
                                    <v-flex xs12 class="mb-0">
                                        <v-text-field label="Author Name" autocomplete="off" required class="pa-0"
                                                      v-model="form.author" name="author"
                                                      :error-messages="form.errors.get('author')"/>
                                    </v-flex>
                                    <v-flex xs12 class="ma-0 pa-0">
                                        <v-textarea solo :error-messages="form.errors.get('description')"
                                                    v-model="form.description" name="description" label="Quote*"
                                                    hint="Short and sweet notice is always eye catching."></v-textarea>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" outlined @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" outlined @click="store">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import Form from '@/library/Form'

    export default {
        data: () => ({
            form: new Form({
                author: '',
                description: ''
            }, '/api/quote'),
            rowsPerPageItems: [5, 10, 25, 50, 75],

            pagination: {
                rowsPerPage: 10
            },
            headers: [
                {text: '#', align: 'left', value: 'id', width: 50, sortable: false},
                {text: 'Quote', align: 'left', value: 'quote', sortable: false},
                {text: 'Author', align: 'center', value: 'author', sortable: false, width: 200},
                {text: 'Action', align: 'right', sortable: false, width: 200}
            ],
        }),
        watch: {
            'pagination': function () {
                this.get();
            }
        },
        mounted() {
            this.get();
        },
        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&');
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                })
            },

            store() {
                this.form.store().then(() => {
                    // this.get();
                });
            }
        }
    }
</script>
<style lang="scss" scoped>
</style>